import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import "../style/job.scss";

const JobPage = ({ data, pageContext, path, location }) => {
  let params;
  if (typeof window !== "undefined") {
    params = window.location?.search;
  }

  const { frontmatter } = data.markdownRemark;
  const content = frontmatter[pageContext.language || "en"];

  const queryString = params?.split("=")[1];

  const decodedString = queryString?.replace(/%20/g, " ");

  const filteredData = content?.jobSection.filter(
    (item) => item.title === decodedString
  );
  console.log("Filtered Data", frontmatter)
  return (
    <Layout language={pageContext.language || "en"} path={path}>
      <section id="job-section">
        <h2>{filteredData && filteredData[0]?.title}</h2>
        <p>{filteredData && filteredData[0]?.description}</p>
        <div className="job-ulist">
          <div className="catchy">
            {filteredData && filteredData[0]?.responsibilities?.name}:
          </div>
          <ul>
            {filteredData &&
              filteredData[0]?.responsibilities?.list.map((item) => {
                return <li>{item}</li>;
              })}
          </ul>
        </div>

        <div className="job-ulist">
          <div className="catchy">
            {filteredData && filteredData[0]?.requirements?.name}:
          </div>
          <ul>
            {filteredData &&
              filteredData[0]?.requirements?.list.map((item) => {
                return <li>{item}</li>;
              })}
          </ul>
        </div>
      </section>
    </Layout>
  );
};

JobPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default JobPage;

export const pageQuery = graphql`
  query JobPageTemplate {
    markdownRemark(frontmatter: { en: { templateKey: { eq: "job-page" } } }) {
      frontmatter {
        en {
          templateKey
          jobSection {
            title
            description
            responsibilities {
              name
              list
            }
            requirements {
              name
              list
            }
          }
        }
        it {
          templateKey
          jobSection {
            title
            description
            responsibilities {
              name
              list
            }
            requirements {
              name
              list
            }
          }
        }
      }
    }
  }
`;
